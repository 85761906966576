import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

class ModalSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.swiper');
    this.dom.closer = this.dom.el.querySelector('[data-js="modal-closer"]');

    this.dom.parent = document.querySelector('[data-js="parent"]');
    this.dom.trigger = this.dom.parent.querySelectorAll('[data-js="modal-trigger"]');

    this.swiper = null;

    this.option = {
      modules: [Navigation],
      loop: true,
      speed: 900,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    this.init();
  }

  init() {
    this.swiper = new Swiper(this.dom.slider, this.option);

    // this.swiper.on('slideChange', () => {
    //   const selected = this.dom.el.querySelector('[data-is="selected"]');
    //   selected.removeAttribute('data-is');
    //   const index = this.swiper.realIndex;
    //   this.dom.thumbnail.children[index].dataset.is = 'selected';

    // });
    
    [...this.dom.trigger].forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        const modalFunc = () => {
          const index = [].slice.call(this.dom.trigger).indexOf(e.currentTarget);
          this.swiper.slideToLoop(index, 0);
          document.body.dataset.is = 'modal';
        }

        if (window.innerWidth < 768) {
          if (!e.currentTarget.classList.contains('is-hover')) {
            e.currentTarget.classList.add('is-hover');
          } else {
            modalFunc();
          }
        } else {
          modalFunc();
        }

      })
    })
    this.dom.closer.addEventListener('click', (e) => {
      document.body.removeAttribute('data-is');
      [...this.dom.trigger].forEach(el=>{
        el.classList.remove('is-hover')
      })
    })
  }
}

export default ModalSlides