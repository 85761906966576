import { CFG } from '../config';
// import { isIE } from '../utility';

class Accordion {
  constructor(el) {
    this.state = false;
    this.dom = {el: el, acd: {}};
    this.dom.acd.trigger = this.dom.el.querySelector('[data-js="acd-trigger"]');
    this.dom.acd.container = this.dom.el.querySelector('[data-js="acd-container"]');

    this.isOpen = false;

    this.toggle = this.toggle.bind(this);

    this.init();
  }

  init() {
    this.state = true;
    this.dom.acd.trigger.addEventListener('click', this.toggle);

    this.resize();
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    if (window.innerWidth < CFG.breakpoint) {
      if (this.isOpen) this.setHeight();
    } else {
      this.close();
      this.dom.acd.trigger.dataset.isOpen = false;
      this.isOpen = false;  
    }
  }

  close() {
    this.dom.acd.container.removeAttribute('style');
  }
  
  open() {
    this.setHeight();
  }

  setHeight() {
    const h = this.getHeight(this.dom.acd.container, this.dom.el);
    this.dom.acd.container.style.height = `${h}px`;
  }

  getHeight(el, parent) {
    const copy = el.cloneNode(true);
    parent.appendChild(copy);
    copy.style.visibility= 'hidden';
    copy.style.height = 'auto';
    copy.style.paddingTop= '0px';
    copy.style.paddingBottom= '0px';
    const ch = copy.clientHeight;
    parent.removeChild(copy);
    return ch;
  }

  toggle(e) {
    if (window.innerWidth < CFG.breakpoint) {
      e.preventDefault();
    }
    if (this.isOpen) {
      this.isOpen = false;
      this.close();
    } else {
      this.isOpen = true;
      this.open();
    }
    if( this.dom.acd.trigger.dataset.isOpen === 'true' ) {
      // this.dom.acd.trigger.removeAttribute('data-is-open');
      this.dom.acd.trigger.dataset.isOpen = false;
    } else {
      this.dom.acd.trigger.dataset.isOpen = true;
    }
  }

  destroy() {
    this.state = false;
    this.dom.acd.trigger.removeEventListener('click', this.toggle);
    if( this.dom.acd.trigger.dataset.isopen === 'true' ){
      this.dom.acd.trigger.removeAttribute('data-is-open');
      this.dom.acd.container.removeAttribute('style');
      this.isOpen = false;
    }
  }
}

export default Accordion