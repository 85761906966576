import smoothscroll from 'smoothscroll-polyfill';
import { CFG } from './config';
import {
    Hamburger,
    Accordion,
    AnchorScroll,
} from './_main';

// home
// import {
//     VisualSlides,
//     RecommendSlides,
//     // AutoMovingSlides,
//     // MagazineSlides,
//     // AreaSlides,
//     // FilteringCategories,
//     // ViewMore
// } from './_home'

// sekou
import {
    SekouSlides,
} from './_sekou'

// modelhouse
import {
    ModalSlides,
} from './_modelhouse'

{
    smoothscroll.polyfill();

    const domHamburger = document.querySelector('[data-js="trigger"]');
    if (domHamburger) new Hamburger(domHamburger);

    const domAnchorScroll = [...document.querySelectorAll('a[href^="#"]')];
    if ( domAnchorScroll ) {
        domAnchorScroll.forEach(el => {
            new AnchorScroll(el);
        });
    }

    const domAccordion = document.querySelectorAll('[data-js="accordion"]');
    if (domAccordion) {
        [...domAccordion].forEach(el=>{
            new Accordion(el);
        });
    }
}

// sekou
{
    const domSekouSlides = document.querySelector('[data-js="sekou-slides"]');
    if (domSekouSlides) new SekouSlides(domSekouSlides);
}

// modelhouse
{
    const domModalSlides = document.querySelector('[data-js="modal"]');
    if (domModalSlides) new ModalSlides(domModalSlides);
}

$(function() {
    $('.hamburger').click(function() {
        $(this).toggleClass('active');

        if ($(this).hasClass('active')) {
            $('.globalMenuSp').addClass('active');
        } else {
            $('.globalMenuSp').removeClass('active');
        }
    });
});

const init = () => {
    var timer = 5000;
    var imgs = document.querySelectorAll('#slider img , #slider-sp img');
    imgs[0].classList.add('show');
    var imgNum = 0;
    function showImg () {
        for (var i = 0; i < imgs.length; i++) {
            imgs[i].classList.remove('show');
        }
        if (imgNum >= imgs.length - 1) {
            imgNum = 0;
        } else {
            imgNum++;
        }
        imgs[imgNum].classList.add('show');
        showImgTimer();
    }
    function showImgTimer () {
        setTimeout(showImg, timer);
    }
    showImgTimer()
}

if (document.querySelector('#slider')) {
    init();
}