import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

class SekouSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.swiper');
    this.dom.thumbnail = this.dom.el.querySelector('[data-js="sekou-thumbnail"]');
    this.dom.thumbnailList = this.dom.el.querySelectorAll('[data-js="sekou-thumbnail-list"]');

    this.swiper = null;

    this.option = {
      modules: [Navigation],
      loop: true,
      speed: 900,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    this.dom.thumbnail.children[0].dataset.is = 'selected';

    this.init();
  }

  init() {
    this.swiper = new Swiper(this.dom.slider, this.option);

    this.swiper.on('slideChange', () => {
      const selected = this.dom.el.querySelector('[data-is="selected"]');
      selected.removeAttribute('data-is');
      const index = this.swiper.realIndex;
      this.dom.thumbnail.children[index].dataset.is = 'selected';

    });
    
    [...this.dom.thumbnailList].forEach(el => {
      el.addEventListener('click', (e) => {
        const index = [].slice.call(this.dom.thumbnailList).indexOf(e.currentTarget);
        this.swiper.slideToLoop(index)
      })
    })
  }
}

export default SekouSlides